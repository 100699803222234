import React, { useCallback, useMemo, useContext, useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';

type ViewType = {
	Views: Record<'MONTH' | 'WEEK' | 'WORK_WEEK' | 'DAY' | 'AGENDA', string>;
}

const { Views } = require('react-big-calendar') as ViewType;
const BigCalendarStyleContext: React.Context<Record<string, string>> = require('react-big-calendar/lib/Styles').default;

import { StyleLoader, ThemeLoader } from '@sightworks/theme';

type MaybeDate = Date | string | number;
type DateUnit = 'milliseconds' | 'seconds' | 'minutes' | 'hours' | 'day' | 'week' | 'month' | 'year' | 'decade' | 'century';
type DateComparer = (a: MaybeDate, b: MaybeDate, unit: DateUnit) => boolean;
interface DateAccessor {
	(d: Date): number;
	(d: MaybeDate, val: number): Date;
}
interface WeekDateAccessor {
	(d: MaybeDate, val?: undefined, firstDay?: number): number;
	(d: MaybeDate, val: number, firstDay?: number): Date;
}
const dates: {
	add(d: MaybeDate, num: number, unit: DateUnit): Date;
	subtract(d: MaybeDate, num: number, unit: DateUnit): Date;
	startOf(d: MaybeDate, unit: DateUnit, firstOfWeek?: 0 | 1 | 2 | 3 | 4 | 5 | 6): Date;
	endOf(d: MaybeDate, unit: DateUnit, firstOfWeek?: 0 | 1 | 2 | 3 | 4 | 5 | 6): Date;
	eq: DateComparer;
	neq: DateComparer;
	gt: DateComparer;
	gte: DateComparer;
	lt: DateComparer;
	lte: DateComparer;
	min(...items: MaybeDate[]): Date;
	max(...items: MaybeDate[]): Date;
	inRange(day: MaybeDate, min?: MaybeDate, max?: MaybeDate, unit?: DateUnit): boolean;
	milliseconds: DateAccessor;
	seconds: DateAccessor;
	minutes: DateAccessor;
	hours: DateAccessor;
	day: DateAccessor;
	date: DateAccessor;
	month: DateAccessor;
	year: DateAccessor;
	decade: DateAccessor;
	century: DateAccessor;
	weekDay: WeekDateAccessor;
	diff(d1: MaybeDate, d2: MaybeDate, unit: DateUnit, asFloat?: boolean): number;
	monthsInYear(year: number): Date[];
	firstVisibleDate(date: MaybeDate, localizer: any): Date;
	lastVisibleDay(date: MaybeDate, localizer: any): Date;
	visibleDays(date: MaybeDate, localizer: any): Date[];
	ceil(date: MaybeDate, unit: DateUnit): Date;
	range(start: Date, end: MaybeDate, unit: DateUnit): Date[];
	merge(date: Date, time: Date): Date;
	eqTime(d1: Date, d2: Date): boolean;
	isJustDate(d: Date): boolean;
	duration(start: Date, end: Date, unit: DateUnit, firstOfWeek?: number): number;
	total(d: Date, unit: DateUnit): boolean;
	week(date: Date): number;
	today(): Date;
	yesterday(): Date;
	tomorrow(): Date;
} = require('react-big-calendar/lib/utils/dates');

import moment, { Moment, MomentTimezone } from 'moment-timezone';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';

const { navigate } = require('react-big-calendar/lib/utils/constants') as { navigate: Record<'PREVIOUS' | 'NEXT' | 'TODAY' | 'DATE', string> };

import { useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '../../components/DialogContent';
import useBreakpoint from '../../utils/useBreakpoint';
import AllEvents from './events';
import DialogTitle from '../../components/DialogTitle';
import DialogActions from '../../components/DialogActions';
import { Context as QueryContext } from '../../utils/query';
import { bool } from 'prop-types';
import { LinkAttributes } from '../../utils/linkAttributes';
import CalendarProps, { View } from './props';

const allViews = Object.values(Views);
const localizer = momentLocalizer(moment);

interface Date extends globalThis.Date {
	format(fmtString: string): string;
}

type DateRangeProps = {
	start: Date;
	end: Date;
	allDay: boolean;
	forDate: boolean;
}

const DateRange = (props: DateRangeProps) => <>{DateRangeInner(props)}</>

const DateRangeInner = ({ start, end, allDay, forDate }: DateRangeProps): string => {
	if (forDate) {
		if (start.format('YYYYMMDD') == end.format('YYYYMMDD')) {
			return start.format('MMMM D, YYYY');
		}
		if (start.format('YYYYMM') == end.format('YYYYMM')) {
			return `${start.format('MMMM D')} - ${end.format('D, YYYY')}`;
		}
		if (start.format('YYYY') == end.format('YYYY')) {
			return `${start.format('MMMM D')} - ${end.format('MMMM D, YYYY')}`;
		}
		return `${start.format('MMMM D, YYYY')} - ${end.format('MMMM D, YYYY')}`;
	}

	if (allDay) {
		return `All day`;
	}

	if (start.format('HHmm') == end.format('HHmm')) return start.format('h:mma');
	return `${start.format('h:mma')} - ${end.format('h:mma')}`;
};

type AgendaDateProps = {
	day: Date
}
const SWAgendaDate = (props: AgendaDateProps) => (
	<>
		{moment(props.day).format('ddd')}
		<br />
		{moment(props.day).format('MMM D')}
	</>
);

type CalendarEventInfoProps = {
	open: boolean;
	handleClose: React.MouseEventHandler;
	id: string;
	start: Date;
	end: Date;
	allDay: boolean;
	title: string;
	location: string;
	link: LinkAttributes
};

const SWCalendarEventInfo = (props: CalendarEventInfoProps) => {
	const linkContext = useContext(QueryContext);
	return (
		<Dialog
			open={props.open}
			onClose={props.handleClose}
			aria-labelledby={`calendar-${props.id}-info`}
			aria-describedby={`calendar-${props.id}-description`}
			maxWidth="sm"
			fullWidth
		>
			<DialogTitle id={`calendar-${props.id}-info`} onClose={props.handleClose}>
				<DateRange start={props.start} end={props.end} allDay={props.allDay} forDate />
			</DialogTitle>
			<DialogContent>
				<DialogContentText id={`calendar-${props.id}-description`} variant="h4">
					{props.title}
				</DialogContentText>
				<DialogContentText variant="subtitle1">
					<DateRange forDate={false} start={props.start} end={props.end} allDay={props.allDay} />
				</DialogContentText>
				{props.location && <DialogContentText variant="subtitle1">{props.location}</DialogContentText>}
				{props.link && 'href' in props.link && (
					<Button
						variant="contained"
						color="primary"
						href={props.link.preserveQuery ? linkContext.extend(props.link.href) : props.link.href}
						target={props.link.openInNewWindow ? '_blank' : '_self'}
					>
						View Details
					</Button>
				)}
			</DialogContent>
			<DialogActions onClose={props.handleClose} />
		</Dialog>
	);
};

const SWCalendar = (props: CalendarProps, ref: React.Ref<any>) => {
	const theme = useTheme();
	const currentMedia = useBreakpoint();
	const isSmallMedia = currentMedia == 'xs' || currentMedia == 'sm';
	const [lastState, setLastState] = useState(isSmallMedia);
	const [getNow, setGetNow] = useState(() => () => new Date(props.initialNow));

	useEffect(() => {
		setLastState(isSmallMedia);
	}, [isSmallMedia]);

	useEffect(() => {
		setGetNow(() => () => new Date());
	}, []);

	const ToolbarComponent = useCallback(
		innerProps => <SWCalendarToolbar {...innerProps} classes={props.classes} props={props} />,
		Object.entries(props.classes).reduce((a: string[], b) => a.concat(b), [])
	);
	const EventComponent = useCallback(
		innerProps => <SWCalendarEvent {...innerProps} classes={props.classes} props={props} />,
		[props.classes.eventLink]
	);

	const CalendarEvents = useMemo(() => {
		return props.calendarItems.map(
			({ id, title, startTime, endTime, timeZone, allDay, link, location }) => ({
				id,
				title,
				start: allDay ? moment.tz(startTime, timeZone).startOf('day') : moment.tz(startTime, timeZone),
				end: allDay ? moment.tz(endTime, timeZone).endOf('day') : moment.tz(endTime, timeZone),
				allDay,
				link,
				openInNewWindow: 'openInNewWindow' in link ? link.openInNewWindow : false,
				location,
			})
		);
	}, [props.calendarItems]);

	if (lastState != isSmallMedia) return null;
	const currentView = isSmallMedia ? props.mobileView : props.desktopView;

	return (
		<Box
			className={clsx(props.classes.root, currentView == 'agenda' ? props.classes.isAgenda : null)}
			key={isSmallMedia ? props.mobileView : props.desktopView}
		>
			<Calendar
				ref={ref}
				events={CalendarEvents}
				views={isSmallMedia ? [props.mobileView] : [props.desktopView]}
				defaultView={isSmallMedia ? props.mobileView : props.desktopView}
				key={isSmallMedia ? props.mobileView : props.desktopView}
				step={60}
				showMultiDayTimes
				localizer={localizer}
				className={clsx(props.classes.calendar, currentView == 'agenda' && props.classes.isAgenda)}
				getNow={getNow}
				components={{
					toolbar: ToolbarComponent,
					event: EventComponent,
					agenda: {
						date: SWAgendaDate as any,
					},
				}}
			/>
		</Box>
	);
};

type SWCalendarEventProps = {
	event: CalendarEventInfoProps;
	isAllDay: boolean;
	classes: Record<string, string>;
	isAgenda: boolean;
}

const SWCalendarEvent = ({ event, isAllDay, classes, isAgenda }: SWCalendarEventProps) => {
	const [open, setIsOpen] = useState(false);
	const [setClosed] = useState(() => () => setIsOpen(false));
	const [setOpen] = useState(() => () => setIsOpen(true));

	let eventPrefix = null;
	if (!isAllDay && !event.allDay) {
		if (!isAgenda && event.start.format('mm') == '00') eventPrefix = event.start.format('ha');
		else eventPrefix = event.start.format('h:mma');
	}

	return (
		<>
			<a onClick={setOpen} className={clsx(classes.eventLink, { [classes.eventAgendaLink]: isAgenda })}>
				{isAgenda ? (
					<>
						{eventPrefix && (
							<>
								<Typography variant="body2" component="span">
									<b>{eventPrefix}</b>
								</Typography>
								<br />
							</>
						)}
						{event.title}
					</>
				) : (
					<>
						{eventPrefix && (
							<>
								{eventPrefix}
								{'\xa0'}
							</>
						)}
						<b>{event.title}</b>
					</>
				)}
			</a>
			<SWCalendarEventInfo {...event} open={open} handleClose={setClosed} />
		</>
	);
};

type SWCalendarNavigationProps = {
	navigate: (event: any, value: keyof typeof navigate) => void;
	buttonSize?: 'small' | 'medium' | 'large';
	classes: Record<string, string>;
	messages: Record<string, string>;
 }

const SWCalendarNavigation = (props: SWCalendarNavigationProps) => {
	const classes = useContext(BigCalendarStyleContext);
	return (
		<ToggleButtonGroup
			exclusive
			value=""
			onChange={props.navigate}
			size={props.buttonSize}
			className={clsx(classes.btnGroup, props.classes.buttonGroup, props.classes.navigation)}
		>
			<ToggleButton value={navigate.PREVIOUS}>
				<Icon color="primary" aria-label={props.messages.previous}>
					keyboard_arrow_left
				</Icon>
			</ToggleButton>
			<ToggleButton value={navigate.TODAY}>
				<Typography variant="button" color="primary">
					{props.messages.today}
				</Typography>
			</ToggleButton>
			<ToggleButton value={navigate.NEXT}>
				<Icon color="primary" aria-label={props.messages.next}>
					keyboard_arrow_right
				</Icon>
			</ToggleButton>
		</ToggleButtonGroup>
	);
};

type SWCalendarViewsProps = {
	view: View;
	views: View[];
	switchView: React.MouseEventHandler;
	classes: Record<string, string>;
	messages: Record<string, string>;
};

const SWCalendarViews = (props: SWCalendarViewsProps) => {
	const classes = useContext(BigCalendarStyleContext);
	return props.views.length > 1 ? (
		<ToggleButtonGroup
			value={props.view}
			exclusive
			onChange={props.switchView}
			className={clsx(classes.btnGroup, props.classes.buttonGroup, props.classes.views)}
		>
			{props.views.map(view => (
				<ToggleButton key={view} value={view}>
					{props.messages[view]}
				</ToggleButton>
			))}
		</ToggleButtonGroup>
	) : null;
};

type SWCalendarToolbarProps = {
	onNavigate: (value: any) => void;
	onView: (value: View) => void;
	classes: Record<string, string>;
	props: CalendarProps;
	localizer: {
		messages: Record<string, string>
	}
	label: string;
}

const SWCalendarToolbar = (props: SWCalendarToolbarProps) => {
	const classes = useContext(BigCalendarStyleContext);
	const doNavigate = useCallback((event, newValue) => props.onNavigate(newValue), [props.onNavigate]);
	const doView = useCallback((event, newValue) => props.onView(newValue), [props.onView]);

	const baseProps = props.props;
	const buttonClasses = {
		buttonGroup: props.classes.toolbarButtonGroup,
		navigation: props.classes.toolbarNavigation,
		views: props.classes.toolbarViews,
	};

	const nav =
		baseProps.showNavigation != 'none' ? (
			<SWCalendarNavigation navigate={doNavigate} classes={buttonClasses} messages={props.localizer.messages} />
		) : null;

	return (
		<Box className={clsx(classes.toolbar, props.classes.toolbar)}>
			{baseProps.showNavigation == 'start' && nav}
			<Typography
				className={clsx(classes.toolbarLabel, props.classes.toolbarTitle)}
				variant={baseProps.titleVariant}
			>
				{props.label}
			</Typography>
			{baseProps.showNavigation == 'end' && nav}
		</Box>
	);
};

export default ThemeLoader(
	StyleLoader(
		SWCalendar,
		makeStyles(
			theme => ({
				root: {
					padding: theme.spacing(1),
					minHeight: 700,
					'&$isAgenda': {
						minHeight: 0,
					},
				},
				calendar: {
					minHeight: 700,
					'&$isAgenda': {
						minHeight: 0,
					},
				},
				isAgenda: {},
				toolbar: {},
				toolbarButtonGroup: {},
				toolbarNavigation: {},
				toolbarViews: {},
				toolbarTitle: {},
				toolbarActive: {},
				eventLink: {
					...theme.typography.body1,
					color: 'inherit',
					display: 'block',
					textDecoration: 'none',
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					'&$eventAgendaLink': {
						color: theme.palette.primary.main,
						transition: theme.transitions.create('color'),
						'&:hover': {
							color: theme.palette.primary[theme.palette.type],
						},
						whiteSpace: 'normal',
						overflow: 'visible',
						textOverflow: 'initial',
					},
				},
				eventAgendaLink: {},
			}),
			{ name: 'SWCalendar' }
		)
	)
);

import Block from './block';
import getChildren from '../../utils/defaultChildren';
import { BlockType } from '../..';

let getProps: typeof import('./getProps').default;
let description: typeof import('./description').default;

if (process.env.BROWSER != 'true') {
	getProps = require('./getProps').default;
}
if (process.env.BROWSER != 'true' || process.env.TARGET == 'admin') {
	description = require('./description').default;
}

const DetailsBlock: BlockType = {
	component: Block,
	getChildren,
	description,
	section: 'Content',
	info: {
		// null | array<string>: what container must this be a direct child of; null = anything.
		// If requiresParent is the empty array, it can never be selected as a child element.
		requiresParent: null,

		// null | array<string>: what container must be somewhere above this; null = anything
		// If requiresAncestor is the empty array, this component can never appear in the tree.
		// Anything specified in requiresParent must also be specified here.
		requiresAncestor: null,

		// null | array<string>: what can this element have for children; null = anything; empty array marks this as an empty component without children.
		permittedChildren: [],

		// array<string>: where can this element not appear
		forbiddenAncestor: [],

		// array<string>: what elements cannot appear inside of this one
		forbiddenDescendant: []
	},
	getProps
};
export default DetailsBlock;


import Block from './block';
import { BlockPropsBase } from '../../props';
import { BlockType } from '../../block-type';

const getProps: typeof import('./getProps').default = process.env.BROWSER != 'true' ? require('./getProps').default : null;
const Button: typeof import('../../compound/button/index').default = (process.env.BROWSER != 'true' || process.env.TARGET == 'admin') ? require('../../compound/button/index').default : null;
const description: typeof import('./description').default = (process.env.BROWSER != 'true' || process.env.TARGET == 'admin') ? require('./description').default : null;

const ArticleButton: BlockType = {
	component: Block,
	getChildren(props: BlockPropsBase): BlockPropsBase[] {
		return props.content;
	},
	description,
	subLabel: Button?.subLabel,
	section: 'Article',
	info: {
		requiresParent: null,
		requiresAncestor: [ 'article/root' ],
		permittedChildren: [],
		forbiddenAncestor: [],
		forbiddenDescendant: []
	},
	getProps
};

export default ArticleButton;


import React, { useState, useRef, useEffect } from 'react';
import { ThemeLoader, StyleLoader } from '@sightworks/theme';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import Skeleton from '@material-ui/lab/Skeleton';
import { useLinkAttributes } from '../../utils/linkContext';
import AvatarProps from './props';

const NoOp = () => {};

const AvatarBlock = (props: AvatarProps) => {
	const lp = useLinkAttributes(props.link);
	const [imgIsLoading, setImgIsLoading] = useState(props.isLoading);
	let Component = 'div';
	const componentProps: {
		className: string;
		href?: string;
		onClick?: React.MouseEventHandler
	} = { className: props.classes.root };

	if (lp) {
		Component = lp.type == 'submit' ? 'button' : 'a';
		componentProps.className = clsx(componentProps.className, props.classes.link);
	}

	if (props.isLoading) {
		if (Component == 'a') {
			componentProps.href = 'javascript:void(0)';
			componentProps.onClick = NoOp;
		}
		componentProps.className = clsx(componentProps.className);
		let inner = <>
			<div className={props.classes.avatarSkeletonContainer}>
				<Skeleton variant="circle" className={clsx(props.classes.avatarLoader, {})} />
			</div>
			{props.title && <Skeleton className={clsx(props.classes.titleLoader, props.classes.title)} />}
			{props.content && <Skeleton className={clsx(props.classes.contentLoader, props.classes.content)} />}
		</>;
		if (Component == 'a') return <a {...componentProps} className={props.classes.skeletonContainer}>{inner}</a>;
		return <div {...componentProps} className={props.classes.skeletonContainer}>{inner}</div>;
	}

	const imageSrc = (() => {
		if (props.image) return `/t/square/matte=none/width=500/scale/f${props.image}/scaled.jpg`;
		if (props.fallbackImage) return `/t/square/matte=none/width=500/scale/f${props.fallbackImage}/scaled.jpg`;
		return null;
	})();

	const handleImageLoad = () => {
		setImgIsLoading(false);
	};

	let inner = <>
		{imgIsLoading && (
			<div className={props.classes.avatarSkeletonContainer}>
				<Skeleton variant="circle" className={props.classes.avatarLoader} />
			</div>
		)}
		<img
			src={imageSrc}
			alt={props.altText}
			className={clsx(props.classes.avatarRoot, {
				[props.classes.isLoading]: imgIsLoading,
			})}
			onLoad={handleImageLoad}
		/>
		{props.title && (
			<Typography variant={props.titleVariant} color={props.titleColor} className={props.classes.title}>
				{props.title}
			</Typography>
		)}
		{props.content && (
			<Typography variant={props.contentVariant} color={props.contentColor} className={props.classes.content}>
				{props.content}
			</Typography>
		)}
	</>

	return React.createElement(Component, { ...componentProps, ...lp }, inner);
};

export default ThemeLoader(
	StyleLoader(
		AvatarBlock,
		makeStyles(
			theme => ({
				root: {
					textAlign: 'center',
					height: '100%',
					width: '100%',
				},
				title: {
					marginTop: theme.spacing(1),
				},
				avatarRoot: {
					borderRadius: '100%',
					height: `auto`,
					width: `100%`,
					'&:hover': {
						opacity: theme.palette.action.hoverOpacity,
					},
					transition: theme.transitions.create('opacity', {
						duration: theme.transitions.duration.short,
					}),
				},
				content: {
					lineHeight: '1.4em',
				},
				link: {
					color: 'inherit',
					textDecoration: 'none',
				},
				avatarSkeletonContainer: {
					height: 0,
					overflow: 'hidden',
					paddingTop: '100%',
					position: 'relative',
				},
				skeletonContainer: {
					marginBottom: 'var(--selected-item-margin, 0)',
				},
				avatarLoader: {
					position: 'absolute',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
				},
				titleLoader: {
					width: '60%',
					margin: 'auto',
					marginTop: '8px',
					height: '5%',
				},
				contentLoader: {
					width: '40%',
					margin: 'auto',
					marginTop: '8px',
					height: '5%',
				},
				isLoading: {
					display: 'none',
				},
			}),
			{ name: 'SwAvatar' }
		)
	)
);

import Block from './block';
import ArticleQuoteProps from './props';
import { BlockPropsBase, BlockType } from '../..';

let description: typeof import('./description').default;
if (process.env.BROWSER != 'true' || process.env.TARGET == 'admin') {
	description = require('./description').default;
}

let getProps: typeof import('./getProps').default;
if (process.env.BROWSER != 'true') {
	getProps = require('./getProps').default;
}

const ArticleQuote: BlockType = {
	component: Block,
	getChildren(props: ArticleQuoteProps): BlockPropsBase[] { return []; },
	description,
	section: 'Article',
	info: {
		requiresParent: null,
		requiresAncestor: [ 'article/root' ],
		permittedChildren: [],
		forbiddenAncestor: [],
		forbiddenDescendant: []
	},
	getProps
};

export default ArticleQuote;
import React, { useContext, useState, useMemo, useEffect } from 'react';
import { StyleLoader, ThemeLoader } from '@sightworks/theme';
import { makeStyles, CSSProperties } from '@material-ui/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import clsx from 'clsx';
import { Context } from '../gallery-container/context';
import { cacheImage } from '../../utils/imageIsCached';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import { Theme } from '@material-ui/core';
import GalleryItemProps from './props';

const GalleryItemBlock = ({ classes, summary, title, src, alt, isLoading, roundedBorder, embeddedObject, aspectRatio, showTitle, titleVariant }: GalleryItemProps, ref: React.Ref<any>) => {
	const { register, toggle } = useContext(Context);
	const [itemIndex, setItemIndex] = useState<number>(0);
	const [imageIsLoading, setImageIsLoading] = useState(true);
	const modSrc = `/t/square/matte=none/width=500/scale/f${src}/scaled.jpg`;

	const handleKeyDown = (e: React.KeyboardEvent) => {
		switch (e.key) {
			case 'Enter':
				toggle(itemIndex);
				break;
			case ' ':
				toggle(itemIndex);
				break;
			default:
				break;
		}
	};

	const handleClick = (e: React.MouseEvent) => {
		toggle(itemIndex);
	};

	const handleLoad = () => setImageIsLoading(false);

	const getIndex = (index: number) => {
		setItemIndex(index);
	};

	useMemo(() => {
		register({ src, title, summary, embeddedObject }, getIndex);
	}, []);

	useEffect(() => {
		cacheImage(modSrc).then(handleLoad, handleLoad);
		// setImageIsLoading(!imageIsCached(modSrc));
	}, []);

	let r: Record<string, string> = { '16:9': '56.25%', '4:3': '75%', '1:1': '100%' };
	
	return (
		<>
			<div
				className={clsx(classes.root, { [classes.loading]: imageIsLoading })}
				onClick={handleClick}
				onKeyDown={handleKeyDown}
				role="button"
				style={{ 
					'--aspect-ratio': r[aspectRatio] || '100%',
					'--icon-size': (embeddedObject ? embeddedObject.iconSize : null) || '2.5rem' } as CSSProperties}
			>
				<div className={classes.itemRoot}>
					<img
						src={modSrc}
						alt={alt}
						className={clsx({ [classes.roundedBorder]: roundedBorder }, classes.item)}
						onLoad={handleLoad}
					/>
					{((embeddedObject && embeddedObject.icon) || (showTitle && title)) && (
						<div className={classes.overlayContent}>
							{embeddedObject && embeddedObject.icon && <Icon className={classes.embedIcon}>
								{embeddedObject.icon}
							</Icon>}
							{showTitle && title && <Typography variant={(titleVariant || 'h4') as any} className={classes.title}>{title}</Typography>}
						</div>
					)}
				</div>
			</div>
			{imageIsLoading && (
				<div style={{ '--aspect-ratio': r[aspectRatio] || '100%' } as CSSProperties} className={classes.skeletonContainer}>
					<Skeleton variant="rect" className={classes.imageLoader} />
				</div>
			)}
		</>
	);
};

export default ThemeLoader(StyleLoader(
	GalleryItemBlock,
	makeStyles(
		(theme: Theme) => ({
			root: { 
				flexGrow: 1, 
				display: 'flex',
				cursor: 'pointer',
			},
			loading: {
				display: 'none',
			},
			itemRoot: {
				flex: 1,
				position: 'relative',
				paddingTop: 'var(--aspect-ratio, 100%)',
				'&:hover > $item': {
					opacity: 0.9,
				}
			},
			item: {
				height: '100%',
				width: '100%',
				position: 'absolute',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				objectFit: 'cover'
			},
			skeletonContainer: {
				height: 0,
				overflow: 'hidden',
				paddingTop: 'var(--aspect-ratio, 100%)',
				position: 'absolute',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0
			},
			imageLoader: {
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
				borderRadius: theme.shape.borderRadius,
			},
			roundedBorder: {
				borderRadius: theme.shape.borderRadius,
			},
			isLoading: {
				display: 'none',
			},
			overlayContent: {
				position: 'absolute',
				top: '50%',
				left: '0',
				right: '0',
				padding: theme.spacing(2),
				textAlign: 'center',
				transform: 'translateY(-50%)',
				color: '#fff',
				textShadow: 'rgba(0, 0, 0, .5) 0 2px 2px'
			},
			embedIcon: {
				fontSize: 'var(--icon-size, 2.5rem)'
			}
		}),
		{ name: 'SWGalleryItem' }
	)
));

import Block from './block';
import { BlockPropsBase } from '../..';
import { BlockType } from '../../block-type';

let getProps: typeof import('./getProps').default;
let description: typeof import('./description').default;

if (process.env.BROWSER != 'true') {
	getProps = require('./getProps').default;
}
if (process.env.BROWSER != 'true' || process.env.TARGET == 'admin') {
	description = require('./description').default;
}

const ArticleRoot: BlockType = {
	component: Block,
	getChildren(props: BlockPropsBase): BlockPropsBase[] { return props.content; },
	description,
	section: 'Specialty',
	info: {
		internalUse: true,
		requiresParent: [],
		requiresAncestor: [],
		permittedChildren: 'button|call-out|credits|download|header|image|list|quote|tabs|text|video'.split('|').map(v => `article/${v}`),
		forbiddenAncestor: [],
		forbiddenDescendant: []
	},
	getProps,
	isRootBlock: true
};

export default ArticleRoot;
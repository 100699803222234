import Block from './block';
import { BlockType } from '../..';
import FilterSelectProps from './props';

let description: typeof import('./description').default;
let getProps: typeof import('./getProps').default;

if (process.env.TARGET == 'admin' || process.env.BROWSER != 'true') {
    description = require('./description').default;
}
if (process.env.BROWSER != 'true') {
    getProps = require('./getProps').default;
}

const FilterSelectBlock: BlockType = {
    component: Block,
    getChildren: (props: FilterSelectProps) => props.content,
    description,
    info: {
        requiresParent: null,
        requiresAncestor: [ 'filter' ],
        permittedChildren: null,
        forbiddenAncestor: [],
        forbiddenDescendant: []
    },
    getProps
}

export default FilterSelectBlock;
import Block from './block';
import { BlockPropsBase, BlockType } from '../..';

let description: typeof import('./description').default;
let getProps: typeof import('./getProps').default;

if (process.env.TARGET == 'admin' || process.env.BROWSER != 'true') {
	description = require('./description').default;
}
if (process.env.BROWSER != 'true') {
	getProps = require('./getProps').default;
}

const GalleryItemBlock: BlockType = {
	component: Block,
	getChildren: (props: BlockPropsBase): BlockPropsBase[] => [],
	description,
	info: {
		// null | array<string>: what container must this be a direct child of; null = anything.
		// If requiresParent is the empty array, it can never be selected as a child element.
		requiresParent: null,

		// null | array<string>: what container must be somewhere above this; null = anything
		// If requiresAncestor is the empty array, this component can never appear in the tree.
		// Anything specified in requiresParent must also be specified here.
		requiresAncestor: [ 'gallery-container' ],

		// null | array<string>: what can this element have for children; null = anything; empty array marks this as an empty component without children.
		permittedChildren: [],

		// array<string>: where can this element not appear
		forbiddenAncestor: [],

		// array<string>: what elements cannot appear inside of this one
		forbiddenDescendant: []
	},
	getProps
};
export default GalleryItemBlock;


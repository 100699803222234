import React from 'react';
import { Registry, BlockPropsBase } from '@sightworks/block';

type WrapFn = (node: React.ReactElement, props: BlockPropsBase, index: number) => React.ReactElement;

export default function getChildren(children: BlockPropsBase[], wrap: WrapFn = null, call: boolean = false) {
	return children
		.map((child, index) => {
			if (child) {
				if (wrap) {
					return wrap(React.createElement(Registry.getBlock(child), child), child, index);
				}
				if (call) {
					let e = Registry.getBlock(child, true);
					while (e.render) e = e.render;
					return e(child);
				}
				return React.createElement(Registry.getBlock(child), {
					key: child.id,
					...child,
				});
			}
			return null;
		})
		.filter(v => !!v);
}

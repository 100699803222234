import { Registry } from '@sightworks/block';
import CssBaseline from '@material-ui/core/CssBaseline';
import React, { Component, Fragment, useEffect, useState } from 'react';
import { StyleLoader, ThemeLoader, ExtendedTheme } from '@sightworks/theme';
import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import PageProps from './props';
import { Theme } from '../../registry';

const Page = (props: PageProps, ref: React.Ref<any>) => {
	return (
		<>
			{React.createElement(Registry.getBlock(props.root), { ...props.root, classes: props.classes, className: props.className, ref, rootComponent: true })}
		</>
	);
};

type ThemeProps = PageProps;

const ThemedPage = ThemeLoader(Page);
let UseTheme = ({ theme, ...rest }: PageProps & { theme: ExtendedTheme }, ref: React.Ref<any>) => {
	const [ ThemedPage, setThemedPage ] = useState<React.FC<ThemeProps> & { defaultTheme: any }>(ThemeLoader(Page, theme));
	useEffect(() => {
		if (ThemedPage.defaultTheme != theme) {
			setThemedPage(ThemeLoader(Page, theme));
		}
	}, [ theme ]);
	return <ThemedPage {...rest} ref={ref}/>;
};
UseTheme = React.forwardRef(UseTheme);

const BasePage = ({ useNamedTheme, ...props }: PageProps, ref: React.Ref<any>) => {
	if (useNamedTheme) {
		let bt = Registry.makeNamedTheme(useNamedTheme, null);
		if (bt) {
			return <UseTheme theme={bt} {...props} ref={ref}/>
		}
	}
	console.log("Base theme");
	return <ThemedPage {...props} ref={ref}/>
}

export default React.forwardRef(BasePage);
export { ThemedPage };


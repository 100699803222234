import Root from './root/index';
import BaseItem from './base-item/index';
import Button from './button/index';
import CallOut from './call-out/index';
import Credits from './credits/index';
import Download from './download/index';
import Header from './header/index';
import Image from './image/index';
import List from './list/index';
import Quote from './quote/index';
import Text from './text/index';
import Video from './video/index';
import Tabs from './tabs/index';
import Tab from './tab/index';

export default {
	'article/base-item': BaseItem,
	'article/button': Button,
	'article/call-out': CallOut,
	'article/credits': Credits,
	'article/download': Download,
	'article/header': Header,
	'article/image': Image,
	'article/list': List,
	'article/quote': Quote,
	'article/text': Text,
	'article/video': Video,
	'article/root': Root,
	'article/tabs': Tabs,
	'article/tab': Tab
};


import FlexGrid from './flex-grid/index';
import Root from './root/index';
import Box from './box/index';
import Carousel from './carousel/index';
import SharedBlockRoot from './shared-block-root/index';
import AppBar from './app-bar/index';
import Toolbar from './toolbar/index';
import Drawer from './drawer/index';
import NavDrawer from './nav-drawer/index';
import NavSection from './nav-section/index';
import SiteNavigation from './site-navigation/index';
import NavItem from './nav-item/index';
import Hidden from './hidden/index';
import Calendar from './calendar/index';
import Collapse from './collapse/index';
import RecordHeader from './record-header/index';
import Dialog from './dialog/index';
import Transition from './transition/index';
import ButtonContainer from './button-container/index';
import ContentBlock from './content/index';

// import ComplexCard from './complex-card/index';

export default {
	'flex-grid': FlexGrid,
	box: Box,
	root: Root,
	carousel: Carousel,
	'shared-block-root': SharedBlockRoot,
	'app-bar': AppBar,
	toolbar: Toolbar,
	drawer: Drawer,
	'nav-drawer': NavDrawer,
	'site-navigation': SiteNavigation,
	'nav-section': NavSection,
	'nav-item': NavItem,
	hidden: Hidden,
	calendar: Calendar,
	collapse: Collapse,
	'record-header': RecordHeader,
	dialog: Dialog,
	transition: Transition,
	'button-container': ButtonContainer,
	'content': ContentBlock
	// 'complex-card': ComplexCard
};

import React, { Fragment, useState, useContext, useEffect, useRef, useCallback } from 'react';
import { ThemeLoader, StyleLoader } from '@sightworks/theme';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import getChildren from '../../utils/children';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useLinkAttributes } from '../../utils/linkContext';
import ButtonBase from '@material-ui/core/ButtonBase';
import NavSectionProps from './props';

const NavSectionBlock = ({ content, classes, title, titleColor, titleVariant, columnCount, breakpoint, link }: NavSectionProps, ref: React.Ref<any>) => {
	let extra = {};
	let lp = useLinkAttributes(link);
	if (lp && (lp.href || lp.onClick)) {
		extra = {
			...lp,
			component: ButtonBase
		}
	};

	return (
		<Grid item xs={12} md={breakpoint ? void 0 : columnCount} classes={classes} {...extra}>
			<Typography variant={titleVariant} color={titleColor}>{title}</Typography>
			{getChildren(content)}
		</Grid>
	)
}

const NavSectionStyle = makeStyles(theme => ({
	root: {
		'& .MuiButtonBase-root': {
			display: 'flex',
			width: '100%',
			justifyContent: 'flex-start',
			marginTop: theme.spacing(1),
		}
	}
}), { name: 'NavSection' });

const ThemedNavSectionBlock = ThemeLoader(StyleLoader(NavSectionBlock, NavSectionStyle));

export default ThemedNavSectionBlock;



import Block from './block';
import { CustomBlockProps } from './props';
import { BlockPropsBase } from '@sightworks/block';

const getProps: typeof import('./getProps').default = (process.env.BROWSER != 'true' && process.env.TARGET != 'admin') ? require('./getProps').default : null;
const description: typeof import('./description').default = (process.env.BROWSER != 'true' || process.env.TARGET == 'admin') ? require('./description').default : null;

export default {
	component: Block,
	getChildren(props: CustomBlockProps): BlockPropsBase[] { return []; },
	getProps,
	description,
	info: {
		internalUse: true,
		baseComponent: false,
		compoundComponent: false,
		rawBlock: false,
		requiresParent: null,
		permittedChildren: [],
		forbiddenDescendant: [],
		forbiddenAncestor: []
	}
};


import Banner from './banner/index';
import Button from './button/index';
import Card from './card/index';
import Details from './details/index';
import Summary from './summary/index';
import NavButton from './nav-button/index';
import Tile from './tile/index';
import ShareFab from './share-fab/index';
import Download from './download/index';

export default {
	banner: Banner,
	button: Button,
	card: Card,
	details: Details,
	summary: Summary,
	'nav-button': NavButton,
	tile: Tile,
	'share-fab': ShareFab,
	download: Download,
};

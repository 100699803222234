import { BlockType } from '..';
import Page from './page/index';
// import AlertBanner from './alert-banner/index';
import CalendarItem from './calendar-item/index';
import GalleryItem from './gallery-item/index';
import GalleryItems from './gallery-items/index';
import GalleryView from './gallery-view/index';
import GalleryContainer from './gallery-container/index';
import GalleryCurrentItem from './gallery-current/index';
import GoogleSearch from './google-search/index';
import GoogleResults from './google-results/index';
import CalendlyWidget from './calendly-widget/index';
import DisqusWidget from './disqus-widget/index';
import GoogleMaps from './google-maps/index';
import AddEventBlock from './add-event/index';
import Filter from './filter/index';
import FilterSelectBlock from './filter-select/index';
import FilterSelectItem from './filter-select-item/index';

const ServerOnly: Record<string, BlockType> = {};
if (process.env.TARGET == 'admin' || process.env.BROWSER != 'true') {
	ServerOnly['content-group'] = require('./content-group').default;
	ServerOnly['content-group-list'] = require('./content-group-list').default;
	ServerOnly['content-item'] = require('./content-item').default;
	ServerOnly['content-item-list'] = require('./content-item-list').default;
	ServerOnly['content-related-list'] = require('./content-related-list').default;
}
console.log(Object.keys(ServerOnly));

export default {
	page: Page,
	'calendar-item': CalendarItem,
	'gallery-item': GalleryItem,
	'gallery-current': GalleryCurrentItem,
	'gallery-items': GalleryItems,
	'gallery-view': GalleryView,
	'gallery-container': GalleryContainer,
	'google-search': GoogleSearch,
	'google-results': GoogleResults,
	'calendly-widget': CalendlyWidget,
	'disqus-widget': DisqusWidget,
	'google-maps': GoogleMaps,
	'add-event': AddEventBlock,
	'filter': Filter,
	'filter-select': FilterSelectBlock,
	'filter-select-item': FilterSelectItem,
	...ServerOnly
	//	'alert-banner': AlertBanner
};

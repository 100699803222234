import { loadThemeModule } from '@sightworks/theme';
import { Registry, BlockPropsBase } from '@sightworks/block';
import { ModulePackageJson } from './index';

export default async function preload(props: BlockPropsBase): Promise<ModulePackageJson[]> {
	if (!props) return [];

	const p: ModulePackageJson[] = [];
	if (props.useStyles) {
		// console.log("Load: %o", props.useStyles);
		await loadThemeModule(props.useStyles);
		p.push(props.useStyles);
	}
	//if (props.useTheme) {
	//	// console.log("Load: %o", props.useTheme);
	//	await loadThemeModule(props.useTheme);
	//	p.push(props.useTheme);
	//}

	const children = Registry.getChildren(props);
	for (const child of children) {
		p.push(...await preload(child));
	}

	return p;
}

import Block from './block';
import ArticleBaseItemProps from './props';
import { BlockPropsBase } from '../../props';
import { BlockType } from '../../block-type';

const BaseItem: BlockType = {
	component: Block,
	getChildren(props: ArticleBaseItemProps): BlockPropsBase[] {
		return props.content;
	},
	description: null,
	info: {
		internalUse: true,
		requiresParent: [],
		requiresAncestor: [],
		permittedChildren: [],
		forbiddenAncestor: [],
		forbiddenDescendant: []
	},
	getProps: null,
	useMarginCustomization: false
};

if (process.env.TARGET == 'admin' || process.env.BROWSER != 'true') {
	BaseItem.description = require('./description').default;
}

if (process.env.BROWSER != 'true') {
	BaseItem.getProps = require('./getProps').default;
}

export default BaseItem;

import PageBlock, { ThemedPage } from './special/page/block';
import { makeStyles } from '@material-ui/styles';
import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles';

import { BlockType, BlockTypeList } from './block-type';
import { BlockPropsBase } from '.';
import Record from './record';
import { ExtendedTheme, ExtendedThemeOptions } from '@sightworks/theme';
import { EventEmitter } from '@sightworks/util';

class EventMap<K, V> extends EventEmitter implements Map<K, V> {
	private _map: Map<K, V>;

	constructor(entries?: readonly (readonly [K, V])[] | null) {
		super();
		this._map = new Map<K, V>(entries);
	}

	clear() {
		if (this.eventNames().includes('delete')) {
			for (let key of this._map.keys()) {
				this.emit('delete', key, this._map.get(key));
				this._map.delete(key);
			}
		} else {
			this._map.clear();
		}
	}

	delete(k: K): boolean {
		if (this._map.has(k)) {
			this.emit('delete', k, this._map.get(k));
		}
		return this._map.delete(k);
	}

	forEach(callbackfn: (value: V, key: K, map: EventMap<K, V>) => void, thisArg: any = this): void {
		this._map.forEach((value, key) => {
			callbackfn.call(thisArg, value, key, this);
		});
	}

	get(key: K): V | undefined {
		return this._map.get(key);
	}

	has(key: K): boolean {
		return this._map.has(key);
	}

	set(key: K, value: V): this {
		if (this._map.has(key)) {
			this.emit('change', key, this._map.get(key), value);
		} else {
			this.emit('add', key, value);
		}
		this._map.set(key, value);
		return this;
	}

	get size() {
		return this._map.size;
	}

	*[Symbol.iterator](): IterableIterator<[K, V]> {
		yield* this._map;
	}

	*entries(): IterableIterator<[K, V]> {
		yield* this._map.entries();
	}

	*keys(): IterableIterator<K> {
		yield* this._map.keys();
	}

	*values(): IterableIterator<V> {
		yield* this._map.values();
	}

	readonly [Symbol.toStringTag]: 'EventMap';
}

class GeneratorEventMap<K, V> extends EventMap<K, V> {
	private _generator: (key: K, map: GeneratorEventMap<K, V>) => V;
	constructor(
		entries?: readonly (readonly [K, V])[] | null,
		generator?: (key: K, map: GeneratorEventMap<K, V>) => V
	) {
		super(entries);
		this._generator = generator;
	}
	get(key: K): V {
		if (!super.has(key)) {
			let value = this._generator(key, this);
			if (value) {
				this.set(key, value);
			}
			return value;
		}
		return super.get(key);
	}

	has(key: K): boolean {
		if (!super.has(key)) {
			let value = this._generator(key, this);
			if (value) {
				this.set(key, value);
				return true;
			}
			return false;
		} else {
			return true;
		}
	}
}

const Registry = new GeneratorEventMap<string, BlockType>(
	null,
	(key: string, map: GeneratorEventMap<string, BlockType>): BlockType => {
		console.log(`Looking for generator of ${key}...`);
		for (let [id, value] of map) {
			if (value.generatorPrefix && value.generator) {
				console.log(`Checking generator: ${value.generatorPrefix}`);
				if (key.startsWith(value.generatorPrefix)) {
					let k = key.substring(value.generatorPrefix.length);
					let v = map.get(k);
					if (v) {
						return value.generator(v);
					}
				}
			}
		}
		return null;
	}
);

type StyleSheet = ReturnType<typeof makeStyles>;
type RawTheme = ExtendedTheme;
type ThemeGen = (theme: ExtendedThemeOptions | {}) => ExtendedTheme;
export type Theme = ExtendedThemeOptions | ThemeGen;
type ThemeItem = {
	theme: Theme
};
type StyleItem = {
	title: string;
	style: any;
	muiStyles?: StyleSheet
};

const NamedStyles = new Map<string, StyleItem>();
export const NamedThemes = new Map<string, ThemeItem>();

let adminAddBlocks: typeof import('./registry-admin').addBlocks;
if (process.env.TARGET == 'admin' || process.env.BROWSER != 'true') {
    adminAddBlocks = require('./registry-admin').addBlocks;
}

export function addBlocks(types: BlockTypeList) {
	let tl: Iterable<[ string, BlockType ]>;

	if (!(Symbol.iterator in types)) {
		tl = Object.entries(types);
	} else {
		tl = types as Iterable<[ string, BlockType ]>;
	}
	for (let [ typeName, type ] of tl) {
		Registry.set(typeName, type);
    }
    if (adminAddBlocks) {
        adminAddBlocks(types);
    }
}

export function removeBlockType(...types: string[]) {
	types.forEach(t => Registry.delete(t))
}

export function *allBlocks() {
	yield* Registry[Symbol.iterator]();
}

export function getBlock(props: BlockPropsBase, raw = false) {
	let b = Registry.get(props.type);
	return (raw ? b.rawComponent : null) || b.component;
}

/** 
 * Given the props for a single block, retrieve it's children.
 *
 * @param {object} props The block properties. This must have a property 'type'.
 * @return {Array.<object>} The props of the child blocks.
 */
export function getChildren(props: BlockPropsBase) {
	return Registry.get(props.type).getChildren(props);
}

/**
 * Retrieve the entire block type from the registry based on it's record.
 *
 * @param {Record} record The block record from the '<channelKey>_blocks' app.
 * @return {BlockType} The block type
 */
export function getBlockInfo(record: Record) {
	if (!record.child_type) {
		console.trace(record);
	}
	return Registry.get(record.child_type);
}

export function getBlockType(id: string) {
	return Registry.get(id);
}

export function getTheme() {
	return ThemedPage.defaultTheme;
}

export function setTheme(t: any) {
	// console.log(`Set theme: `, t);
	ThemedPage.defaultTheme = t;
}

export function addNamedStyles(items: Iterable<[ string, any ]> | { [key: string]: any }) {
	if (items && typeof items == 'object') {
		if (!(Symbol.iterator in items)) items = Object.entries(items);
	}

	for (let [ id, style ] of items as Iterable<[string, any]>) {
		NamedStyles.set(id, style);
	}
}

export function *allNamedStyles() { yield* NamedStyles[Symbol.iterator](); }
export function getNamedStyle(name: string) { return NamedStyles.get(name); }

const placeholderStyle = makeStyles({ root: {} }, { name: 'Placeholder' });

export function makeNamedStyle(name: string) {
	let s = getNamedStyle(name);
	if (s) {
		if (!s.muiStyles) {
			s.muiStyles = makeStyles(s.style, { name });
		}
		return s.muiStyles;
	}
	return placeholderStyle;
}

export function addNamedThemes(items: Iterable<[string, ThemeItem]> | { [key: string]: ThemeItem }) {
	if (items && typeof items == 'object') {
		if (!(Symbol.iterator in items)) items = Object.entries(items);
	}

	for (let [ id, theme ] of items as Iterable<[string, ThemeItem]>) {
		NamedThemes.set(id, theme);
	}
}

export function *allNamedThemes() { yield* NamedThemes[Symbol.iterator](); }
export function removeNamedTheme(name: string) {
	let th = NamedThemes.get(name);
	if (th) {
		NamedThemes.delete(name);
		for (let [ currentTheme, cache ] of Cache) {
			if (cache.has(th)) {
				cache.delete(th);
			}
		}
	}
}
export function getNamedTheme(name: string) { return NamedThemes.get(name); }

const Cache = new Map<ExtendedTheme, Map<ThemeItem, ExtendedTheme>>();
export function makeNamedTheme(name: string, currentTheme: ExtendedTheme): ExtendedTheme {
	let tgt = getNamedTheme(name);
	if (tgt) {
		let c = Cache.get(currentTheme);
		if (!c) {
			c = new Map<ThemeItem, ExtendedTheme>();
			Cache.set(currentTheme, c);
		}
		if (c.has(tgt)) {
			return c.get(tgt);
		}
		let out = tgt.theme;
		let v: ThemeOptions;
		if (typeof tgt.theme == 'function') v = (tgt.theme as ThemeGen)(currentTheme || {});
		else v = out as ThemeOptions;
		c.set(tgt, createMuiTheme(v));
		return c.get(tgt);
	}
	return currentTheme;
}

type UpdaterFn = (url: string) => any;
let updater: UpdaterFn = null;

export function update(href: string) {
	if (updater) updater(href);
	else location.href = href;
}

export function setUpdater(f: UpdaterFn) {
	updater = f;
}

export function *getGenerators(): Iterable<[ string, BlockType ]> {
	for (let [id, type] of Registry) {
		if (type.generator && type.generatorPrefix) {
			yield [id, type];
		}
	}
}
const on = Registry.on.bind(Registry);
const off = Registry.off.bind(Registry);

export { on, off };

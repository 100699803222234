import React from 'react';
import { StyleLoader } from '@sightworks/theme';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import getChildren from '../../utils/children';
import HiddenProps, { HiddenElementProps } from './props';

const HiddenBlock = (props: HiddenProps) => (
	<>
		{getChildren(
			props.content.map(v => ({
				...v,
				classes: {
					root: clsx(props.classes.root, {
						[props.classes.xs]: props.hide.indexOf('xs') != -1,
						[props.classes.sm]: props.hide.indexOf('sm') != -1,
						[props.classes.md]: props.hide.indexOf('md') != -1,
						[props.classes.lg]: props.hide.indexOf('lg') != -1,
						[props.classes.xl]: props.hide.indexOf('xl') != -1,
					}),
				},
			}))
		)}
	</>
);

const HiddenElement = (props: HiddenElementProps) => {
	let child = React.Children.only(props.children) as React.ReactElement<{ hide: undefined, classes: Record<string, string> }>;
	return React.cloneElement(child, {
		hide: void 0,
		classes: {
			...child.props.classes,
			root: clsx(
				(child.props.classes || {}).root,
				{
					[props.classes.xs]: props.hide.indexOf('xs') != -1,
					[props.classes.sm]: props.hide.indexOf('sm') != -1,
					[props.classes.md]: props.hide.indexOf('md') != -1,
					[props.classes.lg]: props.hide.indexOf('lg') != -1,
					[props.classes.xl]: props.hide.indexOf('xl') != -1,					
				}
			)
		}
	})
}

const HiddenStyles = makeStyles(
	theme => ({
		root: {},
		xs: {
			[theme.breakpoints.only('xs')]: { display: 'none !important' },
		},
		sm: {
			[theme.breakpoints.only('sm')]: { display: 'none !important' },
		},
		md: {
			[theme.breakpoints.only('md')]: { display: 'none !important' },
		},
		lg: {
			[theme.breakpoints.only('lg')]: { display: 'none !important' },
		},
		xl: {
			[theme.breakpoints.only('xl')]: { display: 'none !important' },
		},
	}),
	{ name: 'Hidden' }
);

export default StyleLoader(HiddenBlock, HiddenStyles);
const StyledHiddenElement = StyleLoader(HiddenElement, HiddenStyles);
export { StyledHiddenElement as HiddenElement };

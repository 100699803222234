import React from 'react';
import { ThemeLoader, StyleLoader } from '@sightworks/theme';
import Typography from '@material-ui/core/Typography';
import PlaintextProps from './props';

const PlaintextBlock = ({ classes, text }: PlaintextProps, ref: React.Ref<any>) => (
	<Typography {...text} classes={classes} ref={ref}/>
);

export default ThemeLoader(StyleLoader(PlaintextBlock));

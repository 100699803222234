import DisplayedText from './displayedText/index';
import Dropdown from './dropdown/index';
import Header from './header/index';
import Location from './location/index';
import Rating from './rating/index';
import TextField from './textField/index';
import EmailAddress from './emailAddress/index';
import TextArea from './textArea/index';
import Spacer from './spacer/index';
import FileUploadField from './fileUploadField/index';
import { QuestionType } from '../registry';

const QuestionTypes: Record<string, QuestionType> = {
	displayedText: DisplayedText,
	dropdown: Dropdown,
	header: Header,
	location: Location,
	rating: Rating,
	textField: TextField,
	emailAddress: EmailAddress,
	textArea: TextArea,
	spacer: Spacer,
	fileUploadField: FileUploadField
};

export default QuestionTypes;
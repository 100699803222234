import Block from './block';
import ArticleDownloadProps from './props';
import { BlockPropsBase } from '../..';
import { BlockType } from '../../block-type';

let description: typeof import('./description').default;
if (process.env.TARGET == 'admin' || process.env.BROWSER != 'true') {
	description = require('./description').default;
}

let getProps: typeof import('./getProps').default;
if (process.env.BROWSER != 'true') {
	getProps = require('./getProps').default;
}

const ArticleDownload: BlockType = {
	component: Block,
	getChildren(props: ArticleDownloadProps): BlockPropsBase[] {
		return [];
	},
	description,
	section: 'Article',
	info: {
		requiresParent: null,
		requiresAncestor: [ 'article/root' ],
		permittedChildren: [], 
		forbiddenAncestor: [],
		forbiddenDescendant: []
	},
	getProps,
};

export default ArticleDownload;
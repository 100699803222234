import React, { createContext, useState, useEffect, useMemo, useContext, useCallback } from 'react';
import { Context as QueryContext } from './query';
import { Context as NavigatorContext } from './navigator';
import { LinkAttributes } from './linkAttributes';

type LinkContextType = {
	open(event: React.SyntheticEvent): void;
	close?(): void;
}
const LinkContext = createContext<LinkContextType>(null);

export { LinkContext as Context };

type LinkContextProps = React.PropsWithChildren<LinkContextType>;

const Provider = ({ open, children }: LinkContextProps) => {
	return <LinkContext.Provider value={{ open }}>{children}</LinkContext.Provider>;
};

export default Provider;

type LinkProperties = {
	onClick?: React.MouseEventHandler,
	type?: 'submit';
	href?: string;
	target?: string;
}

export function useLinkAttributes(link: LinkAttributes): LinkProperties {
	const queryContext = useContext(QueryContext);
	const linkContext = useContext(LinkContext);
	const navContext = useContext(NavigatorContext);
	const callback = useCallback(
		event => {
			event.preventDefault();
			navContext.navigate(event.currentTarget.href);
		},
		[navContext]
	);

	if (link) {
		if ('context' in link) {
			if (linkContext)
				return { onClick: linkContext.open };
			return null;
		} else if ('submit' in link) {
			return { type: 'submit' };
		} else if ('internal' in link) {
			return { onClick: link.handler };
		} else {
			const lp: LinkProperties = {};
			lp.href = link.preserveQuery ? queryContext.extend(link.href) : link.href;
			if (link.openInNewWindow) lp.target = '_blank';
			if (link.ajaxLoad) lp.onClick = callback;
			return lp;
		}
	}

	return null;
}

import React, { useContext, useCallback } from 'react';
import { StyleLoader, ThemeLoader } from '@sightworks/theme';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import getChildren from '../../utils/children';
import { useLinkAttributes } from '../../utils/linkContext';
import clsx from 'clsx';
import SWButtonProps from './props';

const ButtonBlock = ({ button, link, classes, content }: SWButtonProps, ref: React.Ref<any>) => {
	const lp = useLinkAttributes(link);
	let { buttonRoot, buttonLabel, ...tc } = classes;
	return (
		<Button ref={ref} {...button} {...lp} classes={classes}>
			{getChildren(content)}
		</Button>
	);
};

export default ThemeLoader(
	StyleLoader(
		ButtonBlock,
		makeStyles(
			theme => ({
				root: {},
				label: {}
			}),
			{ name: 'SWButtonContainer' }
		)
	)
);

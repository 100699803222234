import * as path from 'path';
import { inspect } from 'util';

export interface ModulePackageJson {
	readonly location: string;
	readonly moduleId: string;
	readonly identity: string;
}

// Dynamic import of the module.
// Inside of node, the 'module' prop is used.
// When sending this to the client, it gets turned into the object described in toJSON(), which only contains the web-facing bits.
export class ModulePackage implements ModulePackageJson{
	constructor(
		public fileDir: string, 
		public tmpDir: string,
		public target: string,
		public moduleId: string) {}

	get location(): string {
		return `/public/lib/${this.target}.bundle.js`;
	}

	get file(): string {
		return path.resolve(this.fileDir, this.target);
	}

	get module(): string {
		return path.resolve(this.tmpDir, this.target);
	}

	get identity(): string {
		return this.moduleId;
	}
	
	toJSON(): ModulePackageJson {
		return { location: this.location, moduleId: `./public/lib/${this.target}`, identity: this.moduleId };
	}

	[inspect.custom]() {
		return `ModulePackage(${this.location})`;
	}
}

import React, { useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeLoader, StyleLoader } from '@sightworks/theme';
import clsx from 'clsx';
import BoxBlock from '../box/block';
import { Breakpoint } from '../../utils/useBreakpoint';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import RootProps from './props';

const RootContainer = (props: RootProps, ref: React.Ref<any>) => {
	const { rootComponent, className, initialBreakpoint } = props;

	useEffect(() => {
		if (props.googleAnalyticsId) {
			ReactGA.initialize(props.googleAnalyticsId);
			ReactGA.pageview(window.location.pathname + window.location.search);
		}
	}, [ props.googleAnalyticsId ]);

	useEffect(() => {
		if (props.ga4Id) {
			ReactGA4.initialize(props.ga4Id); 
			ReactGA4.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
		}
	}, [ props.ga4Id ]);

	return <>
		{rootComponent && <CssBaseline />}
		<Breakpoint initialValue={initialBreakpoint}>
			<BoxBlock {...props} ref={ref} />
		</Breakpoint>
	</>;
};

export default ThemeLoader(
	StyleLoader(
		RootContainer,
		makeStyles(
			// FIXME: Make sure globalStyles is exposed on Theme
			(theme: any) => ({
				root: {
					'& > .MuiGrid-root': {
						[`&:not(.${theme.globalStyles.base.marginBottom}), &.${theme.globalStyles.base.dividerBottom}`]: {
							marginBottom: 0,
						},
						[`&:not(.${theme.globalStyles.base.marginTop}), &.${theme.globalStyles.dividers.top}`]: {
							marginTop: 0,
						},
						[`&:not(.${theme.globalStyles.base.width})`]: {
							marginLeft: 0,
							marginRight: 0,
							width: '100%',
						},
					},
					// https://github.com/mui-org/material-ui/issues/16367#issuecomment-569829175 accessibility feature
					'@media (prefers-reduced-motion: reduce)': {
						'& *': {
							animationDuration: '0.001ms !important',
							animationIterationCount: '1 !important',
							transitionDuration: '0.001ms !important',
						},
					},
				},
			}),
			{ name: 'SWPageRoot' }
		)
	)
);


import { BlockPropsBase, BlockType } from '../..';
import FilterSelectItemProps from './props';

let description: typeof import('./description').default;
let getProps: typeof import('./getProps').default;

if (process.env.TARGET == 'admin' || process.env.BROWSER != 'true') {
    description = require('./description').default;
}
if (process.env.BROWSER != 'true') {
    getProps = require('./getProps').default;
}

const FilterSelectItemBlock: BlockType = {
    component: null,
    getChildren: (props: FilterSelectItemProps): BlockPropsBase[] => [],
    description,
    info: {
        requiresParent: null,
        requiresAncestor: [ 'filter-select' ],
        permittedChildren: [],
        forbiddenAncestor: [],
        forbiddenDescendant: []
    },
    getProps
};

export default FilterSelectItemBlock;
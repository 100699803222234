import Block from './block';
import { AlertProps } from './props'
import { BlockPropsBase } from '@sightworks/block';
import { BlockType } from '@sightworks/block';

let description: typeof import('./description').default;
let getProps: typeof import('./getProps').default;
let appendNode: typeof import('@sightworks/block').appendNode;

if (process.env.TARGET == 'admin' || process.env.BROWSER != 'true') {
	description = require('./description').default;
	getProps = require('./getProps').default;
}
if (process.env.BROWSER != 'true') {
	appendNode = require('@sightworks/block').appendNode;
}

const AlertBanner: BlockType = {
	component: Block,
	getChildren(props: AlertProps): BlockPropsBase[] {
		return [];
	},
	description,
	section: 'Content',
	getProps,
	info: {
		requiresParent: null,
		permittedChildren: [],
		forbiddenAncestor: [],
		forbiddenDescendant: []
	},
	appendNode
};

export default AlertBanner;

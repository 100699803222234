import Block from './block';
import PlaintextProps from './props';
import { BlockPropsBase, BlockType } from '../..';
import type BlockRecord from '../../record';
import { VariantTypes } from '../../utils/typographyStyles';

const variantMap = {
	h1: 'H1',
	h2: 'H2',
	h3: 'H3',
	h4: 'H4',
	h5: 'H5',
	h6: 'H6',
	subtitle1: 'Subtitle 1',
	subtitle2: 'Subtitle 2',
	body1: 'Body 1',
	body2: 'Body 2',
	caption: 'Caption',
	button: 'Button',
	overline: 'Overline',
	srOnly: 'Screen-reader only',
	inherit: ''
};

let getProps: typeof import('./getProps').default;
let description: typeof import('./description').default;

if (process.env.BROWSER != 'true') {
	getProps = require('./getProps').default;
}
if (process.env.BROWSER != 'true' || process.env.TARGET == 'admin') {
	description = require('./description').default;
}

const PlaintextBlock: BlockType = {
	component: Block,
	getChildren(props: PlaintextProps): BlockPropsBase[] { return []; },
	description,
	async subLabel(block: BlockRecord) {
		return variantMap[block.variant as VariantTypes] || "";
	},
	info: {
		// null | array<string>: what container must this be a direct child of; null = anything.
		// If requiresParent is the empty array, it can never be selected as a child element.
		requiresParent: null,

		// null | array<string>: what container must be somewhere above this; null = anything
		// If requiresAncestor is the empty array, this component can never appear in the tree.
		// Anything specified in requiresParent must also be specified here.
		requiresAncestor: null,

		// null | array<string>: what can this element have for children; null = anything; empty array marks this as an empty component without children.
		permittedChildren: [],

		// array<string>: where can this element not appear
		forbiddenAncestor: [],

		// array<string>: what elements cannot appear inside of this one
		forbiddenDescendant: []
	},
	getProps
};
export default PlaintextBlock;


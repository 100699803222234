import React, { useState, useEffect, useCallback, useRef } from 'react';
import { ThemeLoader, StyleLoader } from '@sightworks/theme';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import { DialogContent, DialogTitle } from '@sightworks/block';
import Slider from 'react-slick';
import Typography from '@material-ui/core/Typography';
import * as Cookie from 'cookie';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import clsx from 'clsx';
import { AlertProps, AlertItem } from './props';

type AlertDialogProps = AlertItem & {
	open: boolean | string;
	handleClose: (event: {}) => void;
	classes: {
		[name: string]: string;
	}
}

const AlertDialog = (props: AlertDialogProps) => (
	<Dialog
		open={!!props.open}
		onClose={props.handleClose}
		aria-labelledby={`alert-${props.id}-title`}
		aria-describedby={`alert-${props.id}-description`}
		maxWidth="sm"
		fullWidth
		className={props.classes.alertDialog}
		style={{
			'--modalBgColor': props.modalBackgroundColor,
			'--modalColor': props.modalForegroundColor,
		} as React.CSSProperties}
	>
		<DialogTitle id={`alert-${props.id}-title`} className={props.classes.alertTitle} onClose={props.handleClose}>
			{props.modalTitle}
		</DialogTitle>
		<DialogContent className={props.classes.alertContent}>
			{props.modalBackgroundImage && (
				<div className={props.classes.dialogImageWrapper}>
					<img className={props.classes.dialogImage} alt="test" src={props.modalBackgroundImage} />
				</div>
			)}
			<DialogContentText id={`alert-${props.id}-description`} className={props.classes.alertContentDescription}>
				{props.modalMessage}
			</DialogContentText>
			{props.linkURL && (
				<Button
					className={props.classes.alertButton}
					href={props.linkURL}
					target={props.newWindow ? '_blank' : void 0}
					variant="contained"
					style={{ color: props.modalButtonTextColor, backgroundColor: props.modalButtonColor }}
					// color={props.bannerButtonColor}
				>
					{props.linkTitle}
				</Button>
			)}
		</DialogContent>
	</Dialog>
);

type AlertBannerProps = AlertItem & {
	autoOpen: (id: string) => void;
	setOpen: (id: string, open: boolean) => void;
	currentOpen: boolean;
	anyOpen: boolean;
	classes: {
		[key: string]: string;
	}
}

const AlertBanner = (props: AlertBannerProps) => {
	console.log('props', props);
	const [overflowed, setOverflowed] = useState(false);
	const [textNode, setTextNode] = useState(null);

	useEffect(() => {
		props.automaticallyOpen && props.autoOpen(props.id);

		const resizeHandler = () => {
			if (textNode && textNode.scrollWidth > textNode.clientWidth) {
				setOverflowed(true);
			} else {
				setOverflowed(false);
			}
		};
		window.addEventListener('resize', resizeHandler, { passive: true });
		requestAnimationFrame(resizeHandler);
		return () => {
			window.removeEventListener('resize', resizeHandler, { passive: true } as EventListenerOptions);
		};
	}, [props.automaticallyOpen, textNode]);

	return (
		<>
			<Paper
				elevation={1}
				className={props.classes.alert}
				style={{
					backgroundColor: props.bannerBackgroundColor,
					backgroundImage: props.bannerBackgroundImage ? `url(${props.bannerBackgroundImage})` : undefined,
				}}
				square
			>
				<div
					className={clsx(props.classes.alertContainer, {
						[props.classes.centerFlex]: props.alignment === 'center',
					})}
				>
					<Typography
						className={props.classes.alertDescription}
						ref={setTextNode}
						style={{ color: props.bannerForegroundColor }}
					>
						{props.bannerMessage}
					</Typography>
					{props.alertType === 'modal' && (
						<Button
							className={props.classes.alertButton}
							onClick={() => props.setOpen(props.id, true)}
							variant="contained"
							style={{ color: props.bannerButtonTextColor, backgroundColor: props.bannerButtonColor }}
							// color={props.bannerButtonColor}
						>
							More
						</Button>
					)}
					{props.alertType === 'external' && (
						<Button
							className={props.classes.alertButton}
							href={props.linkURL}
							target={props.newWindow ? '_blank' : void 0}
							variant="contained"
							style={{ color: props.bannerButtonTextColor, backgroundColor: props.bannerButtonColor }}
							// color={props.bannerButtonColor}
						>
							{props.linkTitle}
						</Button>
					)}
				</div>
			</Paper>
			{props.alertType === 'modal' && (
				<AlertDialog {...props} open={props.currentOpen} handleClose={() => props.setOpen(props.id, false)} />
			)}
		</>
	);
};

const Banners = (props: AlertProps, ref: React.Ref<HTMLDivElement>) => {
	const [currentOpen, setCurrentOpen] = useState<string>(null);
	const [showArrows, setShowArrows] = useState(false);
	const carousel = useRef(null);
	let defaultShown: string[] = [];
	if (typeof document === 'object' && document.cookie) {
		const cv = Cookie.parse(document.cookie);
		if (cv.alertsShown) {
			defaultShown = cv.alertsShown.split(',');
		}
	}
	const [shown, setShown] = useState(defaultShown);
	useEffect(() => {
		if (typeof document === 'object') {
			document.cookie = Cookie.serialize('alertsShown', shown.join(','), {
				maxAge: 86400 * 365,
				sameSite: true,
				path: '/',
			});
		}
	}, [shown]);

	if (currentOpen && shown.indexOf(currentOpen) == -1) {
		setShown(shown.concat(currentOpen));
	}

	const toggleOpen = (id: string, open: boolean) => {
		setCurrentOpen(currentOpen => {
			if (open) {
				return currentOpen || id;
			}
			return currentOpen == id ? null : currentOpen;
		});
	};

	const autoOpen = (id: string) => {
		if (shown.indexOf(id) == -1) {
			toggleOpen(id, true);
		}
	};

	const arrowsShow = () => {
		setShowArrows(true);
	};

	const arrowsHide = () => {
		setShowArrows(false);
	};

	const cycleNext = useCallback(() => carousel.current.slickNext(), []);
	const cyclePrev = useCallback(() => carousel.current.slickPrev(), []);
	if (props.items.length) {
		return (
			<div ref={ref} className={props.classes.root} onMouseEnter={arrowsShow} onMouseLeave={arrowsHide}>
				{props.items.length > 1 && (
					<IconButton
						onClick={cyclePrev}
						className={clsx(props.classes.arrow, props.classes.arrowLeft, {
							[props.classes.hideArrows]: !showArrows,
						})}
					>
						<Icon>keyboard_arrow_left</Icon>
					</IconButton>
				)}
				<Slider
					dots={false}
					easing="linear"
					infinite
					autoplay
					autoplaySpeed={6000}
					pauseOnFocus
					rows={1}
					className={props.classes.slider}
					ref={carousel}
					arrows={false}
				>
					{props.items.map(item => (
						<AlertBanner
							key={item.id}
							autoOpen={autoOpen}
							setOpen={toggleOpen}
							anyOpen={currentOpen != null}
							currentOpen={item.id == currentOpen}
							classes={props.classes}
							{...item}
						/>
					))}
				</Slider>
				{props.items.length > 1 && (
					<IconButton
						onClick={cycleNext}
						className={clsx(props.classes.arrow, props.classes.arrowRight, {
							[props.classes.hideArrows]: !showArrows,
						})}
					>
						<Icon>keyboard_arrow_right</Icon>
					</IconButton>
				)}
			</div>
		);
	}

	return null;
};

const ThemedBanner = ThemeLoader(
	StyleLoader(
		Banners,
		makeStyles(
			theme => ({
				root: {
					position: 'relative',
				},
				arrow: {
					position: 'absolute',
					top: 0,
					bottom: 0,
					padding: 0,
					width: 36,
					height: 36,
					marginTop: 'auto',
					marginBottom: 'auto',
					zIndex: 1,
					[theme.breakpoints.down('xs')]: {
						display: 'none !important',
					},
				},
				arrowLeft: {
					left: 0,
				},
				arrowRight: {
					right: 0,
				},
				hideArrows: {
					display: 'none !important',
				},
				slider: {},
				alert: {
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'center',
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'cover',
					color: theme.palette.text.primary,
					paddingTop: theme.spacing(1),
					paddingBottom: theme.spacing(1),
				},
				alertContainer: {
					display: 'flex',
					width: '100%',
					paddingLeft: 52,
					paddingRight: 52,
				},
				centerFlex: {
					justifyContent: 'center',
				},
				alertDescription: {
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
					padding: theme.spacing(0.75, 2),
				},
				alertDialog: {},
				alertTitle: {
					backgroundColor: 'var(--modalBgColor)',
					color: 'var(--modalColor)',
				},
				alertContent: {
					backgroundColor: 'var(--modalBgColor)',
				},
				alertContentDescription: {
					color: 'var(--modalColor)',
				},
				dialogImageWrapper: {
					width: '50%',
					margin: 'auto',
					marginBottom: theme.spacing(3),
				},
				dialogImage: {
					width: '100%',
					height: 'auto',
					borderRadius: theme.shape.borderRadius,
				},
				alertButton: {},
				alertLink: {},
				alertActions: {},
				alertCloseButton: {},
			}),
			{ name: 'SWAlert' }
		)
	)
);
export default ThemedBanner;

import React from 'react';
import getChildren from '../../utils/children';
import NavItemProps from './props';

const NavItemContainer = ({ classes, content }: NavItemProps) => <>
	{
		getChildren(
			content.map(node => ({ ...node, classes }))
		)
	}
</>;

export default NavItemContainer;

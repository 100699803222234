import React from 'react';
import ReactDOMServer from 'react-dom/server';

let baseCreate = React.createElement;
let newCreate: any = function(...args: Parameters<typeof baseCreate>): ReturnType<typeof baseCreate> {
	let type: any = args[0];
	let props: any = args[1];
	let rest: any[] = args.slice(2);
	if (typeof type == 'string' && props && typeof props == 'object' && 'style' in props) {
		let e = ReactDOMServer.renderToStaticMarkup(baseCreate("div", { style: props.style }));
		if (e.indexOf('style=') != -1) {
			let f = e.indexOf('style="') + 'style="'.length;
			let g = e.indexOf('"', f);
			(props as any)['ie-style'] = e.substring(f, g);
		}
	}
	return baseCreate(type, props, ...rest);
}
React.createElement = newCreate;

import Block from './block';
import AvatarProps from './props';
import { BlockPropsBase, BlockType } from '../..';

let getProps: typeof import('./getProps').default;
let description: typeof import('./description').default;

if (process.env.BROWSER != 'true') {
	getProps = require('./getProps').default;
}
if (process.env.BROWSER != 'true' || process.env.TARGET == 'admin') {
	description = require('./description').default;
}

const AvatarBlock: BlockType = {
	component: Block,
	getChildren(props: AvatarProps): BlockPropsBase[] {
		return [];
	},
	description,
	section: 'Elements',
	info: {
		requiresParent: null,
		requiresAncestor: null,
		permittedChildren: [],
		forbiddenAncestor: [],
		forbiddenDescendant: []
	},
	getProps
};
export default AvatarBlock;


import Freeform from './freeform/index';
import Icon from './icon/index';
import Image from './image/index';
import Plaintext from './plaintext/index';
import Embed from './embed/index';
import Avatar from './avatar/index';

export default {
	freeform: Freeform,
	icon: Icon,
	image: Image,
	plaintext: Plaintext,
	embed: Embed,
	avatar: Avatar
};

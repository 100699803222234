import component from './block';
import type { BlockType, Block, BlockPropsBase } from '@sightworks/block';
import FormProps from './props';

let description: typeof import('./description').default;
let getProps: typeof import('./getProps').default;
let appendNode: typeof import('@sightworks/block').appendNode;

if (process.env.TARGET == 'admin' || process.env.BROWSER != 'true') {
	description = require('./description').default;
}

if (process.env.BROWSER != 'true') {
	getProps = require('./getProps').default;
	appendNode = require('@sightworks/block').appendNode;
}
let T: BlockType = {
	component,
	getChildren: (record: FormProps): BlockPropsBase[] => [ ...record.button, ...record.content ],
	description,
	section: 'Content',
	info: {
		requiresParent: null,
		permittedChildren: null,
		forbiddenAncestor: ['form'],
		forbiddenDescendant: ['form']
	},
	getProps,
	appendNode
}

export default T;

